/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { resolveImage, resolveCB, resolveAboveCta } from "@dataResolvers";
import { Page as PageTemplate } from "@pageTemplates";
import QueryContainer from "@templates/QueryContainer";

export const query = graphql`
  query DisplaysQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "displaysIndex", siteId: $siteId) {
        title
        ... on Craft_displaysIndex_displays_Entry {
          #Hero
          heroTitle
          entrySubtitle
          #Intro
          intro
          # Displays
          displays {
            ... on Craft_displays_displays_Entry {
              title
              url
              metaDescription
              linkAnchor {
                url
                text
              }
              specs {
                ... on Craft_specs_TableRow {
                  heading
                  value
                }
              }
              metaFeaturedImage(withTransforms: ["carouselExtraLarge"]) {
                width(transform: "carouselExtraLarge")
                height(transform: "carouselExtraLarge")
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url(transform: "carouselExtraLarge")
              }
            }
          }
          #Sections
          sections {
            ...SectionFragment
          }
          # Accessories
          heading
          subHeading
          componentsAccessories {
            ... on Craft_componentAccessories_componentAccessory_Entry {
              title
              price
            }
          }
          #Above Footer CTAs
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
          #Meta
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  if (!entry) return null;
  const {
    title,
    heroTitle,
    entrySubtitle,
    intro,
    displays,
    sections,
    heading,
    subHeading,
    componentsAccessories,
    aboveFooterCtaImageBlocks,
    metaFeaturedImage,
    metaDescription,
  } = entry;

  return {
    title,
    hero: {
      title: heroTitle || title,
      subtitle: entrySubtitle,
      image: resolveImage(metaFeaturedImage),
    },
    intro: {
      copy: intro,
    },
    displays: displays?.map(display => {
      return {
        heading: display?.title,
        summary: display?.metaDescription,
        image: resolveImage(display?.metaFeaturedImage),
        link: display?.linkAnchor,
        imageUrl: display?.url,
        specs: display?.specs,
        width: "half",
        centerTitle: true,
        fullWidthImage: true,
      };
    }),
    sections: resolveCB(sections),
    cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    accessories: {
      heading,
      subheading: subHeading,
      componentsAccessories,
    },
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
